@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'assets/styles/customPrimeReact';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}
body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #fb7c51b7 #ffffff;

  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #A200CA -33.05%, #E200AA 145%); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }

  .scroller {
    scrollbar-width: thin; /*thin;*/
    scrollbar-color: #EB249E #ffffff;
  }
  .scroller::-webkit-scrollbar {
    width: 7.5px;
  }
  .scroller::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  .scroller::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #A200CA -33.05%, #E200AA 145%); /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }

  .upload-label-left {
    font-size: 11px;
    position: absolute;
    pointer-events: none;
    top: 50%;
    margin-top: -1.25rem;
    transition-property: all;
    transition-timing-function: ease;
    line-height: 1;
    left: 0.5rem;
    color: #6c757d;
    transition-duration: 0.2s;
  }

  .upload-label-right {
    font-size: 11px;
    position: absolute;
    pointer-events: none;
    top: 50%;
    margin-top: -1.25rem;
    transition-property: all;
    transition-timing-function: ease;
    line-height: 1;
    right: 0.5rem;
    color: #6c757d;
    transition-duration: 0.2s;
  }

  .bgOpacity {
    opacity: 0.5;
  }
}

// text gradient
.text-gradient {
  background: linear-gradient(93.57deg, #7a53bf -2.84%, #ed2c94 46.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

// gradient border

.border-gradient {
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #a200ca -33.05%, #e200aa 145%);
}

.slick-slide {
  padding: 0px 10px;
}

.disc-assessment {
  .p-dropdown .p-dropdown-trigger {
    width: 0px;
    transform: translateX(-10px);
  }
  .pi {
    font-size: 0.7rem;
  }
  .p-dropdown .p-dropdown-label.p-inputtext {
    padding: 0px;
    padding-left: 3px;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.3rem 0.6rem !important;
  }
}

.video-flipped {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 300px;
  border-color: #E200AA;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ql-editor {
  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fb7c51af; /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }
}

.ql-toolbar.ql-snow {
  border: 1px solid #E200AA !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #ecececcc;
}

.p-float-label > label {
  display: flex;
  right: 0.5rem !important;
}

// custom calender styles
.left-side-calender {
  .p-calendar .p-button:not(a):not(.p-disabled) {
    border-right: 0 !important;
    border-left: 1px solid #6600C9 !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .p-calendar .p-inputtext.p-component {
    border-left: 0 !important;
    border-right: 1px solid #6600C9 !important ;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}