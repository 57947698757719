.p-inputtext {
  border: 0.9px #6600C9 solid !important;
  width: 100%;
  font-family: 'Lato', sans-serif !important;
}

.p-sidebar .p-sidebar-header {
  padding: 0 !important;
}

.p-sidebar-right {
  width: 30vw;
}

.p-component {
  font-family: 'Lato', sans-serif !important;
}

.add-job-form {
  .p-inputtext,
  .p-dropdown {
    margin: 6px 0;
  }

  .p-dropdown {
    height: 42px;
  }

  .p-chips-token {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-chips {
    width: 100% !important;
    height: 100% !important;
    scroll-behavior: smooth;
  }
  .p-editor-container {
    width: 100% !important;
    margin-top: 6px !important;
  }

  .p-chips-multiple-container {
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: text;
    overflow-y: auto !important;
    display: block !important;
    margin-top: 6px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    scrollbar-width: thin; /*thin;*/
    scrollbar-color: #D3A6FF #ffffff;

    &::-webkit-scrollbar {
      width: 7.5px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #D3A6FF; /* color of the scroll thumb */
      border-radius: 2px; /* roundness of the scroll thumb */
      /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
    }
  }
}

.p-inputtext:enabled:focus,
.p-inputnumber:enabled:focus,
.p-password:enabled:focus,
.p-dropdown:enabled:focus,
.p-calendar .p-button:not(a):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem #D3A6FF !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #6600C9 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem #D3A6FF !important;
  border-color: #6600C9 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  box-shadow: 0 0 0 0.2rem #D3A6FF !important;
  border-color: #6600C9 !important;
}
.p-focus {
  box-shadow: 0 0 0 0.2rem #D3A6FF !important;
  border-color: #6600C9 !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem #D3A6FF !important;
}
.p-inputnumber {
  width: 100%;
}
.p-password {
  width: 100%;
}

.p-dropdown {
  border: 0.9px #6600C9 solid !important;
  width: 100% !important;
  font-family: 'Lato', sans-serif !important;
}

.p-dropdown .p-dropdown-label.p-inputtext {
  border: none !important;
}

// .p-dropdown-items-wrapper {
//   // background: #FB7C51 !important;
// }

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #6600C9 !important;
  background: #6600C9 !important;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #6600C9 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #6600C9 !important;
  background: #ffffff !important;
}

.p-float-label > label {
  font-size: 14px;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
  right: 0.9rem;
}

.p-dialog {
  border-radius: 8px !important;
}

.p-dialog .p-dialog-header {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.p-dialog-content {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #D3A6FF #ffffff;
  &::-webkit-scrollbar {
    width: 7.5px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D3A6FF; /* color of the scroll thumb */
    border-radius: 2px; /* roundness of the scroll thumb */
    /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
  }
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .p-dialog-mask.p-component-overlay {
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background: linear-gradient(
      115.95deg,
      rgba(248, 247, 247, 0.6) -27.4%,
      rgba(255, 255, 255, 0.08) 58.08%
    ) !important;
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .p-dialog-mask.p-component-overlay {
    background: rgba(255, 255, 255, 0.8) !important;
  }
}

// switch

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #6600C9 !important;
}

.p-inputswitch {
  width: 2.5rem !important;
  height: 1.4rem !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff !important;
  width: 1rem !important;
  height: 1rem !important;
  left: 0.25rem;
  margin-top: -0.5rem !important;
  border-radius: 50% !important;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}
.p-inputswitch:not(.p-inputswitch-checked) .p-inputswitch-slider:before {
  background: transparent !important;
  border: 2px solid #6600C9;
  width: 0.9rem !important;
  height: 0.9rem !important;
  left: 0.2rem;
  margin-top: -0.45rem !important;
  border-radius: 50% !important;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}
.p-inputswitch .p-inputswitch-slider {
  background: transparent !important;
  border: 2px solid #6600C9;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(0.9rem) !important;
}

.p-slider.p-slider-horizontal {
  height: 0.7rem !important;
}

.p-slider {
  background: #eaeaea !important;
  border: 0 none;
  border-radius: 0.7rem !important;
}

.p-slider .p-slider-handle {
  height: 1.8rem !important;
  width: 1.8rem !important;
  background: #6600C9 !important;
  cursor: pointer !important;
  border: 2px solid #6600C9 !important;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #6600C9 !important;
  border-color: #6600C9 !important;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.9rem !important;
  margin-left: -0.9rem !important;
}

.p-slider .p-slider-range {
  background: #c5c5c5 !important;
  border-radius: 0.7rem !important;
}

.searchFunctionality .sortIcon {
  border-bottom: 2px #6600C9 solid !important;
}

.searchFunctionality .p-dropdown,
.searchFunctionality .p-dropdown .p-inputwrapper-focus {
  width: 100%;
  border-radius: 0 !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: 2px #6600C9 solid !important;
}

.searchFunctionality .p-dropdown,
.searchFunctionality .p-dropdown:focus,
.searchFunctionality .p-dropdown:hover,
.searchFunctionality .p-dropdown:active,
.searchFunctionality .p-dropdown .p-focus,
.searchFunctionality .p-dropdown .p-focus:focus,
.searchFunctionality .p-dropdown .p-focus:active,
.searchFunctionality .p-dropdown:not(.p-disabled).p-focus {
  background-color: transparent !important;
  box-shadow: none !important;
  outline: 0px !important;
}

.searchFunctionality .p-inputtext {
  background-color: transparent !important;
  border-radius: 0 !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: 2px #6600C9 solid !important;
}

.searchFunctionality .p-inputtext:enabled:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.assignmentInput {
  .p-inputtextarea {
    background-color: #f8f8f8;
    border: none !important;
  }
  .p-inputtextarea:focus {
    box-shadow: none !important;
  }
}

.p-button:not(a):not(.p-disabled):not(.p-button-icon-only):hover {
  width: 100% !important;
  height: 42px !important;
  background: transparent !important;
  color: #6c757d !important;
  border-color: #6600C9 !important;
}
.p-button:not(a):not(.p-disabled):not(.p-button-icon-only) {
  width: 100% !important;
  height: 42px !important;
  background: transparent !important;
  color: #6c757d !important;
  border-color: #6600C9 !important;
}

.p-button:not(a):not(.p-disabled) .p-button-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.p-calendar {
  width: 100%;
}

.p-calendar .p-button:not(a):not(.p-disabled):hover {
  background: transparent !important;
  color: #6600C9 !important;
  border-color: #6600C9 !important;
  border-left: 0 !important;
}
.p-calendar .p-button:not(a):not(.p-disabled) {
  background: transparent !important;
  color: #6600C9 !important;
  border-color: #6600C9 !important;
  border-left: 0 !important;
}

.p-calendar .p-inputtext.p-component {
  border-right: 0 !important;
}

.p-fileupload .p-fileupload-choose {
  display: flex;
  padding-right: 0.5rem;
  .p-button-icon.pi-upload {
    display: flex;
    order: 2;
  }
  input {
    display: flex;
    order: 1;
  }
}

//   .skillAssessmentTextarea {
//     .p-inputtextarea {
//       background-color: #f4f4f4 !important;
//       border: none !important;
//       resize: none !important;
//     }
//     .p-inputtextarea:focus {
//       box-shadow: none !important;
//     }
//   }

//   .emailDialog {
//     .p-dialog-header {
//       padding-bottom: 0px !important;
//     }
//   }

//   .ql-container {
//     font-size: 1rem !important;
//   }

//   .p-editor-container .p-editor-toolbar {
//     background: #f4f4f4 !important;
//     border-top-right-radius: 0.75rem !important;
//     border-top-left-radius: 0.75rem !important;
//   }

//   .p-editor-container .p-editor-toolbar.ql-snow {
//     border-top: 1px solid #FB7C51 !important;
//     border-right: 1px solid #FB7C51 !important;
//     border-left: 1px solid #FB7C51 !important;
//   }
//   .p-editor-container .p-editor-content.ql-snow {
//     border-bottom: 1px solid #FB7C51 !important;
//     border-right: 1px solid #FB7C51 !important;
//     border-left: 1px solid #FB7C51 !important;
//   }
//   .p-editor-container .p-editor-content {
//     border-bottom-right-radius: 0.75rem !important;
//     border-bottom-left-radius: 0.75rem !important;
//   }
//   .p-editor-container .p-editor-content .ql-editor {
//     background: #ffffff;
//     color: #495057;
//     border-bottom-right-radius: 0.75rem !important;
//     border-bottom-left-radius: 0.75rem !important;
//   }

//   .p-progressbar .p-progressbar-value {
//     background: #FB7C51;
//   }
